<template>
	<div style="width: 100%;height: 100%;">
		<canvas style="width: 50%;margin:20% 25%" ref="qrcodeCanvas"></canvas>
	</div>
</template>

<script>
	import  axios  from  'axios'
	import QRCode from "qrcode"
    import store from "@/store";
	export default {
		data(){
			return{
				url:""
			}
		},
		mounted: function () {
			var  that = this;
			let isMini = window.__wxjs_environment === 'miniprogram';
			if (isMini){
				var MetaInfo = window.getMetaInfo();
				var url = new URL(window.location.href);
				let realName = url.searchParams.get('realName');
				let idCardNo = url.searchParams.get('idCardNo');
				let token = url.searchParams.get('token');
				let tokenType = url.searchParams.get('tokenType');
				if (tokenType==null || tokenType===" " || tokenType=="undefined"){
					tokenType = 1;
				}
				let data= {"MetaInfo":JSON.stringify(MetaInfo),realName:realName,"idCardNo":idCardNo,"token":token};
				if (realName!=null && idCardNo!=null &&token!=null){
					const params = new URLSearchParams();
					for (const sProp in data) {
						params.append(sProp,data[sProp]);
					}
					var allUrl = store.state.devApiUrl+'getInitFaceVerify';
					axios.post(allUrl,params,{
						headers: {
							'Authorization': 'Bearer '+token,
							"Token-Type": tokenType
						}
					}).then(({data}) => {
						if (data.isSuccess==1){
							// console.log(data)
							if (tokenType==1){
								let url = data.data.resultObject.certifyUrl
								const canvas = this.$refs.qrcodeCanvas
								QRCode.toCanvas(canvas, url, error => {
									if (error) console.error(error)
								})
							}else {
								window.location.href = data.data.resultObject.certifyUrl;
							}
						}else {
							this.$message({
								showClose: true,
								message: data.data,
								type: 'error'
							});
						}
						
					}).catch(function(resp){
						that.err(resp);
					});
				}else {
					this.$message({
						showClose: true,
						message: '参数错误！',
						type: 'error'
					});
				}
			}else {
				console.log(isMini);
				that.$message({
					showClose: true,
					message: "请在小程序中打开",
					type: 'error'
				});
				return false;
			}
			
			
		},
	}
</script>



<style scoped>

</style>
